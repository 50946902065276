<template>
  <div class="modal is-active">
    <div v-on="overlayCloses ? { click: cancel } : {}"
      class="modal-background"/>
    <button v-if="closeButton === 'outer' || !title"
      @click="cancel"
      class="modal-close is-large"/>
    <component :is="form ? 'form' : 'div'"
      :class="[cardClass, title ? 'modal-card' : 'modal-content', { 'is-large': wide }]">
      <template v-if="title">
        <header class="modal-card-head"
          :class="headerClass">
          <icon v-if="icon" v-bind="{ icon }" :class="iconClass" />
          <p class="modal-card-title" v-html="title"></p>
          <button v-if="closeButton === 'inner'"
            class="delete"
            @click.prevent="cancel"/>
        </header>
        <section class="modal-card-body"
          :class="[bodyClass, {'is-footerless': !$slots.footer}]">
          <slot/>
        </section>
        <footer v-if="$slots.footer"
          class="modal-card-foot"
          :class="footerClass">
          <slot name="footer"/>
        </footer>
      </template>
      <slot v-else/>
    </component>
  </div>
</template>

<script>
import * as props from '@/mixins/props'

export default {

  mixins: [
    props.title,
    props.bodyClass
  ],

  props: {
    wide: Boolean,
    cardClass: Object | String,
    headerClass: Array | Object | String,
    footerClass: Array | Object | String,

    icon: {
      type: String,
      default: ''
    },
    iconClass: {
      type: Boolean | String,
      default: false,
    },
    overlayCloses: {
      type: Boolean,
      default: true
    },
    closeButton: {
      type: Boolean | String,
      default: 'inner'
    },
    form: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    cancel() {
      this.$parent.$close(false)
    }
  }

}
</script>
