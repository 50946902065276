var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{class:['avatar', { 'no-image': !_vm.hasImage, 'is-rounded': _vm.rounded }],style:({
    display: _vm.inline ? 'inline-flex' : 'flex',
    width: `${_vm.size}px`,
    height: `${_vm.size}px`,
    backgroundImage: _vm.hasImage ? `url(${_vm.path})` : false,
    lineHeight: `${(_vm.size + Math.floor(_vm.size / 20))}px`,
    fontSize: `${Math.floor(this.size / 2.75)}px`
  })},[(!_vm.hasImage)?_c('span',{staticClass:"initials"},[_vm._v(_vm._s(_vm.initials))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }