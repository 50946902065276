export let linkify = (input) => {
  let linkified = input;

  // URLs starting with http://, https://, or ftp://
  linkified = linkified.replace(
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
    '<a class="has-text-link" href="$1" target="_blank" rel="nofollow">$1</a>'
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  linkified = linkified.replace(
    /(^|[^\/])(www\.[\S]+(\b|$))/gim,
    '$1<a class="has-text-link" href="http://$2" target="_blank" rel="nofollow">$2</a>'
  );

  // Change email addresses to mailto:: links.
  linkified = linkified.replace(
    /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim,
    '<a href="mailto:$1">$1</a>'
  );

  // Change support ticket references to routes
  linkified = linkified.replace(
    /\#?\b([A-Z0-9]{3}\-[A-Z0-9]{7})\b/gim,
    `<route new-tab class="ticket-tag has-text-link tooltip" data-tooltip="Open ticket in a new tab" name="support.ticket" :params="{ ticket: '$1' }"><span class="has-text-grey">#</span>$1</route>`
  );

  return linkified;
}
