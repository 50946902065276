<template>
  <nav role="pager">
    <div :class="['buttons', { 'has-addons': canPage && grouped }]">
      <!-- Details -->
      <template v-if="showDetails && !working">
        <static-button :class="detailsClass" v-if="canPage">{{ details }}</static-button>
        <static-button :class="detailsClass" v-else-if="emptyText">{{ emptyText }}</static-button>
        <static-button :class="detailsClass" v-else>{{ meta('total') || 'No' }} {{ meta('total') | plural(context) }}</static-button>
      </template>

      <template v-if="working">
        <static-button :class="detailsClass">{{ workingText }}</static-button>
      </template>

      <!-- Buttons -->
      <template v-if="canPage">
        <action-button
          v-if="jumpControls"
          @click="$emit('nav', link('first'))"
          :disabled="!link('first') || !link('prev') || working"
          :class="buttonClass">
          <icon
            :icon="buttonIcons.firstButtonIcon"
            :pack="buttonIcons.buttonIconPack"
            :type="buttonIcons.buttonIconType"
            :class="iconClass" />
        </action-button>
        <action-button
          @click="$emit('nav', link('prev'))"
          :disabled="!link('prev') || working"
          :left-icon="buttonIcons.prevButtonIcon"
          :left-icon-pack="buttonIcons.buttonIconPack"
          :left-icon-type="buttonIcons.buttonIconType"
          v-bind="{ iconClass }"
          :class="buttonClass">
          {{ prevButtonText }}
        </action-button>
        <action-button
          @click="$emit('nav', link('next'))"
          :disabled="!link('next') || working"
          :right-icon="buttonIcons.nextButtonIcon"
          :right-icon-pack="buttonIcons.buttonIconPack"
          :right-icon-type="buttonIcons.buttonIconType"
          v-bind="{ iconClass }"
          :class="buttonClass">
          {{ nextButtonText }}
        </action-button>
        <action-button
          v-if="jumpControls"
          @click="$emit('nav', link('last'))"
          :disabled="!link('last') || !link('next') || working"
          :class="buttonClass">
          <icon
            :icon="buttonIcons.lastButtonIcon"
            :pack="buttonIcons.buttonIconPack"
            :type="buttonIcons.buttonIconType"
            :class="iconClass" />
        </action-button>
      </template>
    </div>
  </nav>
</template>

<script>
import { format } from '@/utils/string'

export default {

  props: {
    pageable: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
    grouped: {
      type: Boolean,
      default: true,
    },
    format: {
      type: String,
      default: 'Page :current of :last (:total :totalContext)'
    },
    context: {
      type: String,
      default: 'record',
    },
    emptyText: String,
    jumpControls: {
      type: Boolean,
      default: true
    },
    nextButtonText: {
      type: String,
      default: 'Next',
    },
    prevButtonText: {
      type: String,
      default: 'Prev',
    },
    locale: {
      type: String,
      default: 'en-GB',
    },
    buttonIconPack: {
      type: String,
      default: 'fa'
    },
    buttonIconType: {
      type: String,
      default: 'fas'
    },
    nextButtonIcon: {
      type: String,
      default: 'angle-right'
    },
    prevButtonIcon: {
      type: String,
      default: 'angle-left'
    },
    lastButtonIcon: {
      type: String,
      default: 'angle-double-right'
    },
    firstButtonIcon: {
      type: String,
      default: 'angle-double-left'
    },
    iconClass: String,
    buttonClass: String,
    detailsClass: String,
    working: {
      type: Boolean,
      default: false
    },
    workingText: {
      type: String,
      default: 'Please wait, loading data…'
    }
  },

  computed: {

    buttonIcons() {
      return {
        buttonIconPack: this.buttonIconPack,
        buttonIconType: this.buttonIconType,
        nextButtonIcon: this.nextButtonIcon,
        prevButtonIcon: this.prevButtonIcon,
        lastButtonIcon: this.lastButtonIcon,
        firstButtonIcon: this.firstButtonIcon,
      }
    },

    details() {
      let { meta } = this
      return format(this.format, {
        current: meta('current_page'),
        last: meta('last_page'),
        total: meta('total').toLocaleString(this.locale),
        totalContext: this.$options.filters.plural(meta('total'), this.context)
      })
    },

    isApiResource() {
      let { pageable } = this
      return ['links', 'meta'].every(key => {
        return key in pageable && Object.keys(pageable[key]).length > 0
      })
    },

    canPage() {
      let { pageable } = this
      return this.isApiResource
        ? (pageable.links.prev || pageable.links.next)
        : (pageable.prev_page_url || pageable.next_page_url)
    },

    link() {
      let { pageable } = this
      return key => this.isApiResource
        ? pageable.links[key]
        : pageable[`${key}_page_url`]
    },

    meta() {
      let { pageable } = this
      return key => this.isApiResource
        ? pageable.meta[key]
        : pageable[key]
    }
  }
}
</script>

<style>
nav[role="pager"] .buttons .button.is-static.is-expanded {
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
}
</style>
