<template>
  <hr v-if="separator" class="dropdown-divider">
  <a v-else-if="!custom && !hasLink" class="dropdown-item" :class="anchorClasses" @click="selectItem">
    <slot/>
  </a>
  <div v-else :class="itemClasses" @click="selectItem">
    <slot/>
  </div>
</template>

<script>
import * as props from '@/mixins/props'

export default {

  mixins: [
    props.disabled
  ],

  props: {
    value: {
      type: Array|Boolean|Function|Number|Object|String|Symbol,
      default: null
    },
    separator: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    hasLink: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    anchorClasses() {
      return {
        'is-disabled': this.disabled,
        'is-paddingless': this.paddingless,
        'is-active': this.value !== null && this.value === this.$parent.selected
      }
    },

    itemClasses() {
      return {
        'dropdown-item': !this.hasLink,
        'is-disabled': this.disabled,
        'is-paddingless': this.paddingless,
        'is-active': this.value !== null && this.value === this.$parent.selected,
        'has-link': this.hasLink
      }
    },

    clickable() {
      return !this.separator && !this.disabled && !this.custom
    }
  },

  methods: {
    selectItem() {
      if (!this.clickable) {
        return
      }
      this.$parent.selectItem(this.value)
      this.$emit('click')
    }
  },

  created() {
    if (!this.$parent.$data.isDropdown) {
      this.$destroy()
      throw new Error('Dropdown items must be wrapped in a dropdown.')
    }
  }
}
</script>
