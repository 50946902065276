<template>
  <article class="message" :class="{ ' is-hidden': dismissed }">
    <div class="message-header" v-if="title || $slots.header">
      <slot name="header" v-if="$slots.header"/>
      <p v-else>{{ title }}</p>
      <button class="delete" @click="dismissMessage" v-if="canClose"/>
    </div>
    <div class="message-body" :class="bodyClass">
      <slot/>
    </div>
  </article>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'
import * as methods from '@/mixins/methods'

export default {

  mixins: [
    props.bodyClass,
    props.title,
    props.closeable,
    computed.canClose,
    methods.close
  ],

  created() {
    const localStorageValue = localStorage.getItem(this.title);
    if (localStorageValue === 'true') {
      this.dismissed = true;
    }
  },

  data: () => ({
    dismissed: false
  }),

  methods: {
    dismissMessage() {
      this.dismissed = true;
      localStorage.setItem(this.title, 'true');
    }
  }
}
</script>
