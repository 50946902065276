<template>
  <div class="dropdown" ref="dropdown" :class="classes">
    <div ref="trigger" class="dropdown-trigger" @click.prevent="toggle">
      <slot name="trigger" v-bind="{ isActive, isFlipped }"/>
    </div>
    <transition :name="transition">
      <div v-show="isActive || hoverable" ref="menu" class="dropdown-menu">
        <div class="dropdown-content" v-prevent-parent-scroll>
          <slot/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Popper from 'popper.js'
import * as props from '@/mixins/props'

export default {

  mixins: [
    props.disabled
  ],

  props: {
    value: {
      type: Array|Boolean|Function|Number|Object|String|Symbol,
      default: null
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
    mobileModal: {
      type: Boolean,
      default: true,
    },
    transition: {
      type: String,
      default: 'fade'
    },
    position: {
      type: String,
      default: 'bottom-start'
    }
  },

  created() {
    if (typeof window !== 'undefined') {
      document.addEventListener('click', this.clickOutside)
    }
  },

  data() {
    return {
      selected: this.value,
      isActive: false,
      isFlipped: false,
      isDropdown: true, // used by DropdownItem
      popper: null, // an internal reference to the popper instance
    }
  },

  mounted() {
    this.popper = new Popper(this.$refs.trigger, this.$refs.menu, {
      placement: this.position,
      onUpdate: (data) => {
        if (this.isActive) {
          this.isFlipped = data.flipped
          let method = this.isFlipped ? 'add' : 'remove'
          this.$refs.dropdown.classList[method]('is-flipped')
        }
      }
    })
  },

  computed: {
    classes() {
      return {
        'is-disabled': this.disabled,
        'is-hoverable': this.hoverable,
        'is-active': this.isActive,
      }
    },
    isMobileModal() {
      return this.mobileModal && !this.hoverable
    }
  },

  methods: {
    setSelected(value) {
      this.selected = value
    },

    emitActive(active) {
      this.$emit('active', active)
    },

    selectItem(value) {
      if (this.selected !== value) {
        this.$emit('change', value)
        this.setSelected(value)
      }
      this.$emit('input', value)
      this.isActive = false
    },

    isWhitelisted(el) {
      if (el === this.$refs.menu || el === this.$refs.trigger) {
        return true
      }

      // Dropdown children
      if (this.$refs.menu !== undefined) {
        let children = this.$refs.menu.querySelectorAll('*')
        for (const child of children) {
          if (el === child) {
            return true
          }
        }
      }

      // Trigger children
      if (this.$refs.trigger !== undefined) {
        let children = this.$refs.trigger.querySelectorAll('*')
        for (const child of children) {
          if (el === child) {
            return true
          }
        }
      }

      return false
    },

    clickOutside({ target }) {
      if (!this.isWhitelisted(target)) {
        this.isActive = false
      }
    },

    toggle() {
      if (this.disabled || this.hoverable) {
        return
      }
      this.isActive = !this.isActive
      this.$nextTick(() => this.popper.scheduleUpdate())
    },
  },

  watch: {
    value: 'setSelected',
    isActive: 'emitActive',
  },

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickOutside)
    }
  }

}
</script>
