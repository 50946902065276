<template>
  <article
    :class="['avatar', { 'no-image': !hasImage, 'is-rounded': rounded }]"
    :style="{
      display: inline ? 'inline-flex' : 'flex',
      width: `${size}px`,
      height: `${size}px`,
      backgroundImage: hasImage ? `url(${path})` : false,
      lineHeight: `${(size + Math.floor(size / 20))}px`,
      fontSize: `${Math.floor(this.size / 2.75)}px`
    }">
    <span class="initials" v-if="!hasImage">{{ initials }}</span>
  </article>
</template>

<script>
export default {
  props: {
    path: String,
    name: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 48
    },
    rounded: {
      type: Boolean,
      default: true
    },
    inline: {
      type: Boolean,
      default: false
    },
    empty: {
      type: String,
      default: '?'
    }
  },

  data: () => ({
    hasImage: false,
  }),

  created() {
    this.render()
  },

  watch: {
    path: 'render'
  },

  computed: {
    initials() {
      let initials = this.name.match(/\b(\w)/g)

      if (!initials) return this.empty
      initials = initials.length > 2
        ? [initials[0], initials[initials.length - 1]]
        : initials

      return initials.join('').toUpperCase()
    },
  },

  methods: {
    render() {
      if (this.path) {
        let image = new Image
        image.onerror = () => this.hasImage = false
        image.onload = () => this.hasImage = true
        image.src = this.path
      }
    },
  }
}
</script>
