<template>
  <article class="notification">
    <button class="delete" @click="close" v-if="canClose"/>
    <slot/>
  </article>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'
import * as methods from '@/mixins/methods'

export default {

  mixins: [
    props.closeable,
    computed.canClose,
    methods.close
  ],

}
</script>
